import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  useToast,
  Icon,
  Image,
  Flex,
} from "@chakra-ui/react";
import { FiCopy } from "react-icons/fi";
import { useParams } from "react-router-dom";
import icon from "../../assets/logo.png";

const Header = ({ listData }) => {
  const { shareId } = useParams();
  const toast = useToast();

  const handleCopyLink = () => {
    const shareLink = `https://share.hoobe.pro/lists/${shareId}`;
    navigator.clipboard
      .writeText(shareLink)
      .then(() => {
        toast({
          title: "Link copied!",
          description: "The share link has been copied to your clipboard.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom",
        });
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: "Unable to copy the link. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "bottom",
        });
      });
  };

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="center"
        borderBottomWidth="1px"
        borderBottomColor="gray.300"
        py={6}
      >
        <Image src={icon} alt="hoo.be logo" boxSize="40px" mr={3} />
        <Heading size="lg" color="gray.800">
          hoo.be
        </Heading>
      </Flex>

      <Box maxW="1400px" mx="auto" my={6} p={4}>
        <Flex
          direction={["column", "column", "row"]}
          alignItems={["center", "center", "flex-start"]}
          justifyContent={["center", "center", "space-between"]}
          textAlign={["center", "center", "left"]}
        >
          <Box mb={[4, 4, 0]}>
            <Heading size="xl" color="gray.800">
              {listData.name}
            </Heading>
            <Text fontSize="lg" color="gray.600" mt={2}>
              {listData.description}
            </Text>
          </Box>
          <Button
            leftIcon={<Icon as={FiCopy} />}
            bg="#222222"
            color="white"
            size={["sm", "sm", "md"]}
            onClick={handleCopyLink}
            _hover={{ bg: "blackAlpha.800" }}
            transition="all 0.2s ease-in-out"
          >
            Copy
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;
