import React, { useState } from "react";
import {
  Box,
  Heading,
  Image,
  Text,
  Grid,
  GridItem,
  IconButton,
  Input,
} from "@chakra-ui/react";
import { FaStar, FaRegStar } from "react-icons/fa";
import { FormatNumber } from "./FormatNumber";
import Followers from "./Followers";
import Deliverables from "./Deliverables";
import verifiedBadge from "../../assets/verifiedbadge.png";

const CreatorCard = ({
  creator,
  visibleColumns,
  allowEdit,
  onToggleFavorite,
  onNoteChange,
  onDeliverablesChange,
}) => {
  const [editingNote, setEditingNote] = useState(false);
  const [note, setNote] = useState(creator.notes || "");

  const handleNoteClick = () => {
    if (allowEdit.notes) {
      setEditingNote(true);
    }
  };

  const handleNoteBlur = () => {
    setEditingNote(false);
    onNoteChange(creator.creatorId, note);
  };

  const calculateTotalImpressions = () => {
    let total = 0;
    creator.deliverables.forEach((deliverable) => {
      switch (deliverable.name) {
        case "Instagram Post":
          total +=
            creator.creatorInfo.instagram_profile.projected_post_views *
            deliverable.count;
          break;
        case "Instagram Story":
          total +=
            creator.creatorInfo.instagram_profile.projected_story_views *
            deliverable.count;
          break;
        case "Instagram Reel":
          total +=
            creator.creatorInfo.instagram_profile.projected_reel_views *
            deliverable.count;
          break;
        default:
          break;
      }
    });
    return total;
  };

  return (
    <Box
      borderRadius="xl"
      bg="#F4F5F7"
      overflow="hidden"
      p={5}
      border="1px solid #e2e8f0"
      position="relative"
    >
      {visibleColumns.favorite && (
        <IconButton
          icon={
            creator.isFavorite ? <FaStar color="#fcba03" /> : <FaRegStar />
          }
          onClick={() => onToggleFavorite(creator.creatorId)}
          aria-label="Toggle Favorite"
          bg="#F4F5F7"
          fontSize="24px"
          position="absolute"
          top="10px"
          left="10px"
          zIndex={1000}
          isDisabled={!allowEdit.favorite}
        />
      )}

      {visibleColumns.creator && (
        <>
<Box display="flex" justifyContent="center" alignItems="center">
  <Box position="relative">
    <Image
      src={creator.creatorInfo.instagram_profile.profile_pic_url}
      alt={creator.creatorInfo.name}
      borderRadius="full"
      boxSize="100px"
      objectFit="cover"
    />
    {creator.creatorInfo.instagram_profile.is_verified && (
      <Image
        src={verifiedBadge}
        alt="Verified"
        boxSize="30px"
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
      />
    )}
  </Box>
</Box>



          <Heading as="h2" size="md" mt={4} textAlign="center">
            {creator.creatorInfo.name}
          </Heading>
          <Text as="h3" fontSize="16px" mt={2} textAlign="center">
            @{creator.creatorInfo.instagram_profile.username}
          </Text>
        </>
      )}

      {visibleColumns.category && (
        <Text as="h3" fontSize="16px" mt={2} textAlign="center">
          {creator.creatorInfo.instagram_profile.category}
        </Text>
      )}

      {visibleColumns.followers && (
        <Box mt={2} textAlign="center" fontSize="sm">
          <Followers
            followersCount={
              creator.creatorInfo.instagram_profile.followers_count
            }
            username={creator.creatorInfo.instagram_profile.username}
          />
        </Box>
      )}

      <Grid mt={4} templateColumns="repeat(2, 1fr)" gap={2}>
        {visibleColumns.postImpressions && (
          <GridItem>
            <Box
              border="1px solid #e2e8f0"
              p={2}
              borderRadius="xl"
              textAlign="center"
              fontSize="13px"
            >
              Post Impressions
              <br />
              <Text fontSize="lg" fontWeight="bold">
                {FormatNumber(
                  creator.creatorInfo.instagram_profile.projected_post_views
                )}
              </Text>
            </Box>
          </GridItem>
        )}
        {visibleColumns.storiesImpressions && (
          <GridItem>
            <Box
              border="1px solid #e2e8f0"
              p={2}
              borderRadius="xl"
              textAlign="center"
              fontSize="13px"
            >
              Story Impressions
              <br />
              <Text fontSize="lg" fontWeight="bold">
                {FormatNumber(
                  creator.creatorInfo.instagram_profile.projected_story_views
                )}
              </Text>
            </Box>
          </GridItem>
        )}
        {visibleColumns.reelsImpressions && (
          <GridItem>
            <Box
              border="1px solid #e2e8f0"
              p={2}
              borderRadius="xl"
              textAlign="center"
              fontSize="13px"
            >
              Reel Impressions
              <br />
              <Text fontSize="lg" fontWeight="bold">
                {FormatNumber(
                  creator.creatorInfo.instagram_profile.projected_reel_views
                )}
              </Text>
            </Box>
          </GridItem>
        )}
        {visibleColumns.engagementRate && (
          <GridItem>
            <Box
              border="1px solid #e2e8f0"
              p={2}
              borderRadius="xl"
              textAlign="center"
              fontSize="13px"
            >
              Eng. Rate <br />
              <Text fontSize="lg" fontWeight="bold">
                {(
                  creator.creatorInfo.instagram_profile.engagement_rate * 100
                ).toFixed(2)}
                %
              </Text>
            </Box>
          </GridItem>
        )}
        {visibleColumns.expectedImpressions && (
          <GridItem colSpan={2}>
            <Box
              border="1px solid #e2e8f0"
              p={2}
              borderRadius="xl"
              textAlign="center"
              fontSize="13px"
            >
              Total Impressions
              <br />
              <Text fontSize="lg" fontWeight="bold">
                {FormatNumber(calculateTotalImpressions())}
              </Text>
            </Box>
          </GridItem>
        )}
      </Grid>

      {visibleColumns.deliverables && (
        <Box mt={2}>
          <Deliverables
            initialDeliverables={creator.deliverables}
            onChange={(updatedDeliverables) =>
              onDeliverablesChange(creator.creatorId, updatedDeliverables)
            }
            isEditable={allowEdit.deliverables}
          />
        </Box>
      )}

      {visibleColumns.notes && (
        <Box mt={2}>
          <Box
            border="1px solid #e2e8f0"
            borderRadius="xl"
            bg="white"
            onClick={handleNoteClick}
          >
            {editingNote && allowEdit.notes ? (
              <Input
                value={note}
                onChange={(e) => setNote(e.target.value)}
                onBlur={handleNoteBlur}
                placeholder="Add a note..."
                autoFocus
                borderRadius="xl"
              />
            ) : (
              <Text p={2} color={note ? "black" : "gray.400"}>
                {note || "Add a note..."}
              </Text>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CreatorCard;