// FormatNumber.js

export const FormatNumber = (number) => {
    if (!number && number !== 0) return "N/A";
  
    let formattedCount;
  
    if (number >= 1000000 && number < 10000000) {
      formattedCount = (number / 1000000).toFixed(1) + "M";
    } else if (number >= 10000000) {
      formattedCount = Math.floor(number / 1000000) + "M";
    } else if (number >= 10000 && number < 100000) {
      formattedCount = (number / 1000).toFixed(1) + "K";
    } else if (number >= 100000) {
      formattedCount = Math.floor(number / 1000) + "K";
    } else if (number >= 1000 && number < 10000) {
      formattedCount = (number / 1000).toFixed(1) + "K";
    } else {
      return number.toString();
    }
  
    return formattedCount.replace('.0', '');
  };