import React, { useMemo } from "react";
import { SimpleGrid, Box, Text } from "@chakra-ui/react";

const Overview = ({ listData, totalImpressions }) => {
  const totalCreators = listData.creators.length;

  const totalFollowers = useMemo(() => {
    return listData.creators.reduce((sum, creator) => {
      return sum + creator.creatorInfo.instagram_profile.followers_count;
    }, 0);
  }, [listData]);

  const averageEngagementRate = useMemo(() => {
    if (totalCreators === 0) return 0;
    const totalEngagement = listData.creators.reduce((sum, creator) => {
      return sum + creator.creatorInfo.instagram_profile.engagement_rate * 100;
    }, 0);
    return (totalEngagement / totalCreators).toFixed(2);
  }, [listData, totalCreators]);

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    } else {
      return num.toString();
    }
  };

  return (
    <Box>
      <SimpleGrid columns={[1, 1, 2, 4]} spacing={4}>
        <Box borderWidth="1px" py={4} px={8} textAlign="left" borderRadius="xl">
          <Text fontSize="lg">Total Creators</Text>
          <Text fontSize="2xl"  fontWeight="bold">{totalCreators}</Text>
        </Box>
        <Box borderWidth="1px" py={4} px={8} textAlign="left" borderRadius="xl">
          <Text fontSize="lg">Total Followers</Text>
          <Text fontSize="2xl"  fontWeight="bold">{formatNumber(totalFollowers)}</Text>
        </Box>
        <Box borderWidth="1px" py={4} px={8} textAlign="left" borderRadius="xl">
          <Text fontSize="lg">Avg Eng. Rate</Text>
          <Text fontSize="2xl"  fontWeight="bold">{averageEngagementRate}%</Text>
        </Box>
        <Box borderWidth="1px" py={4} px={8} textAlign="left" borderRadius="xl">
          <Text fontSize="lg">Total Impressions</Text>
          <Text fontSize="2xl"  fontWeight="bold">{formatNumber(totalImpressions)}</Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Overview;
