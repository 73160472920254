import React, { useState, useCallback, useMemo } from "react";
import {
  Box,
  SimpleGrid,
  useToast,
  Input,
  VStack,
  HStack,
  Select,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import axios from "axios";
import { useParams } from "react-router-dom";
import debounce from "lodash/debounce";
import CreatorCard from "./CreatorCard";
import Overview from "./Overview";
import Header from "./Header"; // Import Header component

const ShareCards = ({ data }) => {
  const { shareId } = useParams();
  const [creators, setCreators] = useState(data.listData.creators);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState(""); // New state for sorting
  const visibleColumns = data.shareData.visibleColumns;
  const allowEdit = data.shareData.allowEdit;
  const toast = useToast();

  // Determine if Engagement Rate is available for all creators
  const isEngagementRateAvailable = useMemo(() => {
    return creators.every(
      (creator) =>
        creator.creatorInfo &&
        creator.creatorInfo.instagram_profile &&
        creator.creatorInfo.instagram_profile.engagement_rate !== undefined &&
        creator.creatorInfo.instagram_profile.engagement_rate !== null &&
        !isNaN(creator.creatorInfo.instagram_profile.engagement_rate)
    );
  }, [creators]);

  const updateCreatorData = async (creatorId, updates) => {
    try {
      const response = await axios.post(
        "https://hoobe-server-v2.onrender.com/share-save",
        {
          shareId,
          creatorId,
          ...updates,
        }
      );

      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.error("Error updating creator data:", error);
      toast({
        title: "Error updating creator data",
        description: "Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
  };

  const handleToggleFavorite = async (creatorId) => {
    if (allowEdit.favorite) {
      const creator = creators.find((c) => c.creatorId === creatorId);
      const newFavoriteStatus = !creator.isFavorite;
      const success = await updateCreatorData(creatorId, {
        isFavorite: newFavoriteStatus,
      });
      if (success) {
        setCreators((prevCreators) =>
          prevCreators.map((c) =>
            c.creatorId === creatorId
              ? { ...c, isFavorite: newFavoriteStatus }
              : c
          )
        );
      }
    }
  };

  const handleNoteChange = async (creatorId, updatedNote) => {
    const success = await updateCreatorData(creatorId, { notes: updatedNote });
    if (success) {
      setCreators((prevCreators) =>
        prevCreators.map((c) =>
          c.creatorId === creatorId ? { ...c, notes: updatedNote } : c
        )
      );
    }
  };

  // eslint-disable-next-line
  const debouncedUpdateDeliverables = useCallback(
    debounce((creatorId, updatedDeliverables) => {
      updateCreatorData(creatorId, { deliverables: updatedDeliverables });
    }, 3000),
    [shareId]
  );

  const handleDeliverablesChange = (creatorId, updatedDeliverables) => {
    setCreators((prevCreators) =>
      prevCreators.map((c) =>
        c.creatorId === creatorId
          ? { ...c, deliverables: updatedDeliverables }
          : c
      )
    );
    debouncedUpdateDeliverables(creatorId, updatedDeliverables);
  };

  const filteredCreators = useMemo(() => {
    return creators.filter((creator) => {
      const searchLower = searchTerm.toLowerCase();
      return (
        creator.creatorInfo.name.toLowerCase().includes(searchLower) ||
        creator.creatorInfo.instagram_profile.username
          .toLowerCase()
          .includes(searchLower) ||
        creator.creatorInfo.instagram_profile.name
          .toLowerCase()
          .includes(searchLower)
      );
    });
  }, [creators, searchTerm]);

  const sortedCreators = useMemo(() => {
    const sorted = [...filteredCreators];
    switch (sortOption) {
      case "name-asc":
        sorted.sort((a, b) =>
          a.creatorInfo.name.localeCompare(b.creatorInfo.name)
        );
        break;
      case "name-desc":
        sorted.sort((a, b) =>
          b.creatorInfo.name.localeCompare(a.creatorInfo.name)
        );
        break;
      case "followers-asc":
        sorted.sort(
          (a, b) =>
            a.creatorInfo.instagram_profile.followers -
            b.creatorInfo.instagram_profile.followers
        );
        break;
      case "followers-desc":
        sorted.sort(
          (a, b) =>
            b.creatorInfo.instagram_profile.followers -
            a.creatorInfo.instagram_profile.followers
        );
        break;
      case "impressions-asc":
        sorted.sort((a, b) => {
          const aImpressions = a.deliverables.reduce((sum, d) => {
            switch (d.name) {
              case "Instagram Post":
                return (
                  sum +
                  a.creatorInfo.instagram_profile.projected_post_views * d.count
                );
              case "Instagram Story":
                return (
                  sum +
                  a.creatorInfo.instagram_profile.projected_story_views *
                    d.count
                );
              case "Instagram Reel":
                return (
                  sum +
                  a.creatorInfo.instagram_profile.projected_reel_views * d.count
                );
              default:
                return sum;
            }
          }, 0);
          const bImpressions = b.deliverables.reduce((sum, d) => {
            switch (d.name) {
              case "Instagram Post":
                return (
                  sum +
                  b.creatorInfo.instagram_profile.projected_post_views * d.count
                );
              case "Instagram Story":
                return (
                  sum +
                  b.creatorInfo.instagram_profile.projected_story_views *
                    d.count
                );
              case "Instagram Reel":
                return (
                  sum +
                  b.creatorInfo.instagram_profile.projected_reel_views * d.count
                );
              default:
                return sum;
            }
          }, 0);
          return aImpressions - bImpressions;
        });
        break;
      case "impressions-desc":
        sorted.sort((a, b) => {
          const aImpressions = a.deliverables.reduce((sum, d) => {
            switch (d.name) {
              case "Instagram Post":
                return (
                  sum +
                  a.creatorInfo.instagram_profile.projected_post_views * d.count
                );
              case "Instagram Story":
                return (
                  sum +
                  a.creatorInfo.instagram_profile.projected_story_views *
                    d.count
                );
              case "Instagram Reel":
                return (
                  sum +
                  a.creatorInfo.instagram_profile.projected_reel_views * d.count
                );
              default:
                return sum;
            }
          }, 0);
          const bImpressions = b.deliverables.reduce((sum, d) => {
            switch (d.name) {
              case "Instagram Post":
                return (
                  sum +
                  b.creatorInfo.instagram_profile.projected_post_views * d.count
                );
              case "Instagram Story":
                return (
                  sum +
                  b.creatorInfo.instagram_profile.projected_story_views *
                    d.count
                );
              case "Instagram Reel":
                return (
                  sum +
                  b.creatorInfo.instagram_profile.projected_reel_views * d.count
                );
              default:
                return sum;
            }
          }, 0);
          return bImpressions - aImpressions;
        });
        break;
      case "engagement-rate-asc":
        sorted.sort(
          (a, b) =>
            a.creatorInfo.instagram_profile.engagement_rate -
            b.creatorInfo.instagram_profile.engagement_rate
        );
        break;
      case "engagement-rate-desc":
        sorted.sort(
          (a, b) =>
            b.creatorInfo.instagram_profile.engagement_rate -
            a.creatorInfo.instagram_profile.engagement_rate
        );
        break;
      case "favorites":
        sorted.sort((a, b) => b.isFavorite - a.isFavorite);
        break;
      default:
        break;
    }
    return sorted;
  }, [filteredCreators, sortOption]);

  const totalImpressions = useMemo(() => {
    return creators.reduce((total, creator) => {
      const creatorTotal = creator.deliverables.reduce(
        (creatorSum, deliverable) => {
          let impressions = 0;
          switch (deliverable.name) {
            case "Instagram Post":
              impressions =
                creator.creatorInfo.instagram_profile.projected_post_views *
                deliverable.count;
              break;
            case "Instagram Story":
              impressions =
                creator.creatorInfo.instagram_profile.projected_story_views *
                deliverable.count;
              break;
            case "Instagram Reel":
              impressions =
                creator.creatorInfo.instagram_profile.projected_reel_views *
                deliverable.count;
              break;
            default:
              break;
          }
          return creatorSum + impressions;
        },
        0
      );
      return total + creatorTotal;
    }, 0);
  }, [creators]);

  // Responsive max width for Select component
  const selectMaxWidth = useBreakpointValue({ base: "100%", md: "250px" });

  return (
    <Box>
      <Header listData={data.listData} /> {/* Use Header here */}
      <Box p={4} maxW="1400px" mx="auto">
        <VStack spacing={4} align="stretch">
          <Overview
            listData={data.listData}
            totalImpressions={totalImpressions}
          />
          <HStack spacing={4} flexDirection={{ base: "column", md: "row" }}>
            <Input
              placeholder="Search creators..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="lg"
              borderRadius="xl"
              flex="1"
            />
            <Tooltip label="Sort Creators" aria-label="Sort Creators">
              <Select
                placeholder="Sort by"
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                size="lg"
                borderRadius="xl"
                maxW={selectMaxWidth}
                width="100%"
              >
                <option value="name-asc">Name (A-Z)</option>
                <option value="name-desc">Name (Z-A)</option>
                <option value="followers-asc">Followers (Ascending)</option>
                <option value="followers-desc">Followers (Descending)</option>
                <option value="impressions-asc">
                  Impressions (Low to High)
                </option>
                <option value="impressions-desc">
                  Impressions (High to Low)
                </option>
                {isEngagementRateAvailable && (
                  <>
                    <option value="engagement-rate-asc">
                      Engagement Rate (Low to High)
                    </option>
                    <option value="engagement-rate-desc">
                      Engagement Rate (High to Low)
                    </option>
                  </>
                )}
                <option value="favorites">Favorites First</option>
              </Select>
            </Tooltip>
          </HStack>
          <SimpleGrid columns={[1, 1, 2, 4]} spacing={4}>
            {sortedCreators.map((creator) => (
              <CreatorCard
                key={creator.creatorId}
                creator={creator}
                visibleColumns={visibleColumns}
                allowEdit={allowEdit}
                onToggleFavorite={handleToggleFavorite}
                onNoteChange={handleNoteChange}
                onDeliverablesChange={handleDeliverablesChange}
              />
            ))}
          </SimpleGrid>
        </VStack>
      </Box>
    </Box>
  );
};

export default ShareCards;
