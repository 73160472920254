import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Lists from "./components/Lists";

// Custom theme with Poppins font and hiding scrollbar
const theme = extendTheme({
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Poppins, sans-serif",
  },
  colors: {
    blue: {
      300: "#b0e6eb",
      400: "#66cbd4",
      500: "#5cb5bd",
      600: "#44939a",
    },
  },
  styles: {
    global: {
      // Hide scrollbar for all browsers
      'html, body': {
        overflowY: "scroll", // Keep scrolling enabled
      },
      '::-webkit-scrollbar': {
        display: "none", // Hide the scrollbar for Chrome, Safari, Edge
      },
      'html': {
        msOverflowStyle: "none", // Hide scrollbar for IE and Edge
      },
      'body': {
        scrollbarWidth: "none", // Hide scrollbar for Firefox
      },
    },
  },
});

function Redirect() {
  useEffect(() => {
    window.location.href = "https://hoobe.pro";
  }, []);

  return null;
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/lists/:shareId" element={<Lists />} />
          <Route path="*" element={<Redirect />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
